import React,{useState,useEffect} from 'react';
import {fetchApi, fetchShell} from '../../utils/fetchApi';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DELETE_USER, GET_USERS } from '../../queries/customers';
import classes from './customersList.module.css';
import moment from 'moment';
import Button from '../../components/Button';
import { Delete, Edit, Search, Trash2, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Textfield from '../../components/Fields/Textfield';
import Select from 'react-select'
import Loader from '../../components/Loader';
import ReactSwitch from 'react-switch';
import toast from 'react-hot-toast';


export default function CustomersList(props){
    let nav = useNavigate();
    let getUser = JSON.parse(localStorage.getItem('USER'));
    const [listCustomers,setListCustomers] = useState([]);
    const [listRoles,setListRoles] = useState([]);
    const [modaleOpen,setModaleOpen] = useState(false);
    const [typeModale,setTypeModale] = useState(null);
    const [idSelected,setIdSelected] = useState(null);
    const [customSelected,setCustomSelected] = useState(null);
    const [states, setStates] = useState({});
    const [userErrors, setUserErrors] = useState({});
    const [modaleLoading, setModaleLoading] = useState(false);
    const [deleteQuery, { dataDelete, loadingDelete, errorDelete }] = useMutation(DELETE_USER);

    const [getUsers, { loading, error, data }] = useLazyQuery(GET_USERS, {
        // variables: { inputText },
        fetchPolicy: 'cache-and-network',
    });

    const getRolesUsers = async() => {
        let result = await fetchApi(`stti/groups`,null, 'GET', true)
        let arrayRoles = [{
            label: 'SUPER ADMIN',
            value: 'superadmin'
        }]
        for (let role of result){
            arrayRoles.push({
                label: role,
                value: role
            })
        }
        arrayRoles.sort((a,b) => {
            let fa = a.value.toLowerCase(),
                fb = b.value.toLowerCase();
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        })
        setListRoles(arrayRoles)
    }



    useEffect(()=>{
        if (getUser?.userGroup.code !== 'superadmin'){
            nav('/')
        }
        getUsers();
        getRolesUsers();
        props.logout(true, false);
    },[])

    useEffect(()=>{
        if (data)
            setListCustomers(data.allSttiUsers)
    },[data])

    useEffect(()=>{
        if (error)
            console.log('error', error)
    },[error])

    const resetStates = () => {
        setIdSelected(null)
        setTypeModale(null)
        setModaleOpen(false);
    }
    const handleClose = () => {
        resetStates()
    }
    const handleClickAdd = async() => {
        setStates({
            sendToClient: true,
            seeStock: true
        });
        setTypeModale('add');
        setModaleOpen(true);
    }
    const handleClickEdit = async(customer) => {

        setIdSelected(customer.id);
        setStates({
            firstname: customer.firstname,
            lastname: customer.lastname,
            email: customer.email,
            newEmail: customer.email,
            role: {
                label: customer.userGroup.code === "superadmin" ? 'superadmin' : customer.userGroup.code,
                value: customer.userGroup.code === "superadmin" ? customer.userGroup.code : customer.userGroup.id
            },
            seeStock: customer.seeStock,
            password: ''
        })
        setTypeModale('edit');
        setModaleOpen(true);
    }
    const handleClickDelete = async(id) => {
        setIdSelected(id)
        setTypeModale('delete');
        setModaleOpen(true);
    }

    const checkNewUserError = () => {
        var errors = {};

        if (!states?.email || states.email === '') errors.email = true

        if (!states?.firstname || states.firstname === '') errors.firstname = true
        
        if (!states?.lastname || states.lastname === '') errors.lastname = true

        if (!states?.role || states.role === '') errors.role = true    
        
        return errors
    }

    const checkFieldNewUserError = (field, value) => {
        var errors = {}

        if (!value || value === '') {
            errors[field] = true    
        } else errors[field] = false
        
        setUserErrors({...userErrors, ...errors})
    }

    const handleAdd = async() => {

        var errors = await checkNewUserError();
        
        if (errors && Object.keys(errors).length > 0) {
            console.log(errors)
            return setUserErrors(errors)
        }

        setModaleLoading(true)

        let result = await fetchApi(`stti/customuser/create`,{
            "email": states.email,
            "firstname": states.firstname,
            "lastname": states.lastname,
            "group": (states.role.label === 'SUPER ADMIN') ? 'superadmin' : states.role.label,
            "sendToClient": states.sendToClient ? states.sendToClient : null,
            "seeStock": states.seeStock,
            "receiver": states.receiver
        }, 'POST', true);
        if (result.success){
            getUsers();
            resetStates();
        }

        setModaleLoading(false)
    }
    const handleEdit = async() => {
        let result = await fetchApi(`stti/customuser/edit`,{
            "email": states.email,
            "new_email": states.newEmail,
            "firstname": states.firstname,
            "lastname": states.lastname,
            "group": states.role.label,
            "seeStock": states.seeStock,
        }, 'POST', true);
        if (result.success){
            getUsers();
            resetStates();
        } else {
            toast.error('Une erreur est survenue.')
        }
    }

    const handleEditPassword = async() => {
        setModaleLoading(true)

        let result = await fetchApi(`stti/customuser/edit-password`,{
            "userId": idSelected.replace('/api/stti-users/', ''),
            "password": states.password,
            "receiver": states.receiver
        }, 'POST', true);

        if (result.success){
            getUsers();
            resetStates();
        } else {
            toast.error('Une erreur est survenue.')
        }

        setModaleLoading(false)
    }

    const handleDelete = async() => {
        await deleteQuery({ variables: {id: idSelected}});
        getUsers();
        resetStates();
    }
    const handleChange = (stateName, value) => {
        let getStates = {...states};
        getStates[stateName] = value;
        setStates(getStates)

        checkFieldNewUserError(stateName, value)
    }

    const renderErrorLabel = (input) => {
        if (!userErrors[input]) {
            return null
        }

        return (
            <p className={classes.errorLabel}>Ce champs est requis</p>
        )
    }

    const renderModaleContent = () => {
        if (modaleLoading) {
            return <Loader />
        }

        switch(typeModale){
            case 'delete':
                return (
                    <div className={classes.contentModale}>
                        <h3>Supprimer un utilisateur</h3>
                        <p>Êtes-vous sûr de vouloir supprimer cet utilisateur, ceci est une action irréversible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.</p>
                        <div className={classes.containerButtonsModale}>
                            <Button className={classes.buttonBlue} label={"Annuler"} onClick={() => handleClose()}/>
                            <Button className={classes.buttonRed} label={"Supprimer"} onClick={() => handleDelete()}/>
                        </div>
                    </div>
                )
            case 'add':
                return (
                    <div className={classes.contentModale}>
                        <h3>Ajouter un utilisateur</h3>
                        <div className={classes.containerListInputs}>
                            <div className={classes.containerInput}>
                                <label>Nom *</label>
                                <Textfield label={'Nom'} placeholder={'Nom'} name={'firstname'} defaultValue={states.firstname} onChange={handleChange}
                                    style={{borderColor: userErrors?.firstname ? 'red' : null}}
                                />

                                {renderErrorLabel('firstname')}
                            </div>
                            <div className={classes.containerInput}>
                                <label>Prénom *</label>
                                <Textfield label={'Prénom'} placeholder={'Prénom'} name={'lastname'} defaultValue={states.lastname} onChange={handleChange}
                                    style={{borderColor: userErrors?.lastname ? 'red' : null}}
                                />

                                {renderErrorLabel('lastname')}
                            </div>
                            <div className={classes.containerInput}>
                                <label>Email *</label>
                                <Textfield label={'Email'} placeholder={'Email'} name={'email'} defaultValue={states.email} onChange={handleChange} type={'email'}
                                    style={{borderColor: userErrors?.email ? 'red' : null}}
                                />

                                {renderErrorLabel('email')}
                            </div>
                            <div className={classes.containerInput}>
                                <label style={{marginBottom: 8}}>Rôle *</label>
                                <Select borderColor={'red'} options={listRoles} defaultValue={states.role} onChange={(event) => handleChange('role', event)}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor: userErrors?.role ? 'red' : '#a3a3a3',
                                        })
                                    }}
                                />
                                {renderErrorLabel('group')}
                            </div>
                            <div className={classes.containerInput}>
                                <label style={{marginBottom: 8}}>Vue sur les stocks</label>
                                <ReactSwitch onChange={(event) => handleChange('seeStock', event)} checked={states.seeStock} />
                            </div>
                            <div className={classes.containerInput}>
                                <label style={{marginBottom: 8}}>Envoyer un mail à l'utilisateur</label>
                                <ReactSwitch onChange={(event) => handleChange('sendToClient', event)} checked={states.sendToClient} />
                            </div>
                            {
                                states.sendToClient ?
                                null
                                :
                                <div className={classes.containerInput}>
                                    <label>Email de réception du mot de passe *</label>
                                    <Textfield label={'Receiver'} placeholder={'Email de réception'} name={'receiver'} defaultValue={states.receiver} onChange={handleChange}/>
                                </div>
                            }
                            <div className={classes.containerButtonsModale}>
                                <Button className={classes.buttonBlue} label={"Annuler"} onClick={() => handleClose()}/>
                                <Button className={classes.buttonYellow} label={"Ajouter"} onClick={() => handleAdd()}/>
                            </div>
                        </div>
                    </div>
                )
            case 'edit':
                return (
                    <div className={classes.contentModale}>
                        <h3>Modifier un utilisateur</h3>
                        <div className={classes.containerListInputs}>
                            <div className={classes.containerInput}>
                                <label>Nom</label>
                                <Textfield label={'Nom'} placeholder={'Nom'} name={'firstname'} defaultValue={states.firstname} onChange={handleChange}/>
                            </div>
                            <div className={classes.containerInput}>
                                <label>Prénom</label>
                                <Textfield label={'Prénom'} placeholder={'Prénom'} name={'lastname'} defaultValue={states.lastname} onChange={handleChange}/>
                            </div>
                            <div className={classes.containerInput}>
                                <label>Email</label>
                                <Textfield isDisabled={states.role.value === "superadmin" ? true : false} label={'Email'} placeholder={'Email'} name={'newEmail'} defaultValue={states.newEmail} onChange={handleChange}/>
                            </div>
                            <div className={classes.containerInput}>
                                <label style={{marginBottom: 8}}>Rôle</label>
                                <Select isDisabled={states.role.value === "superadmin" ? true : false} options={listRoles} defaultValue={states.role} onChange={(event) => handleChange('role', event)}/>
                            </div>
                            <div className={classes.containerInput}>
                                <label style={{marginBottom: 8}}>Vue sur les stocks</label>
                                <ReactSwitch onChange={(event) => handleChange('seeStock', event)} checked={states.seeStock} />
                            </div>
                            <div className={classes.containerButtonsModale}>
                                <Button className={classes.buttonBlue} label={"Annuler"} onClick={() => handleClose()}/>
                                <Button className={classes.buttonYellow} label={"Modifier le mot de passe"} onClick={() => {
                                   setModaleLoading(true);
                                   /**
                                    * Without timeout, password keep firstname value
                                    */
                                   setTimeout(() => {
                                        setTypeModale('edit-password');
                                        setModaleLoading(false);
                                   }, 200);
                                }} disabled={states.role.value === "superadmin" ? true : false}/>
                                <Button className={classes.buttonYellow} label={"Modifier"} onClick={() => handleEdit()}/>
                            </div>
                        </div>
                    </div>
                )
            case 'edit-password':
                return (
                    <div id='changed' className={classes.contentModale}>
                        <h3>Modifier le mot de passe</h3>
                        <div className={classes.containerListInputs}>
                            <div className={classes.containerInput}>
                                <label>Le nouveau mot de passe</label>
                                <Textfield label={'Mot de passe'} 
                                    placeholder={'Mot de passe'} name={'password'} value={states.password} onChange={handleChange}
                                    isDisabled={states.role.value === "superadmin" ? true : false}
                                />
                            </div>
                            <div className={classes.containerInput}>
                                <label style={{marginBottom: 8}}>Envoyer un mail à l'utilisateur</label>
                                <ReactSwitch onChange={(event) => handleChange('sendToClient', event)} checked={states.sendToClient} />
                            </div>
                            {
                                states.sendToClient ?
                                null
                                :
                                <div className={classes.containerInput}>
                                    <label>Email de réception du mot de passe *</label>
                                    <Textfield label={'Receiver'} placeholder={'Email de réception'} name={'receiver'} defaultValue={states.receiver} onChange={handleChange}/>
                                </div>
                            }
                            <div className={classes.containerButtonsModale}>
                                <Button className={classes.buttonBlue} label={"Annuler"} onClick={() => handleClose()}/>
                                <Button className={classes.buttonYellow} label={"Modifier"} onClick={() => handleEditPassword()}/>
                            </div>
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    return(
        <div className={classes.root}>
            <h1 className={classes.title}>Liste des utilisateurs</h1>
            <div className={classes.containerButtons}>
                <Button className={classes.buttonBlue} label={"Ajouter un utilisateur"} icon={<User size="1rem" style={{fill: "#ffc000", stroke:"#ffc000"}}/>} onClick={handleClickAdd}/>
            </div>
            {loading ?
                <Loader label="Chargement en cours" />
                :
                listCustomers.length > 0 ?
                    <div className={classes.containerTable}>
                        <table className={classes.tableCustomers}>
                            <thead>
                                <tr>
                                    <th>Utilisateur</th>
                                    <th>Email</th>
                                    <th>Rôle</th>
                                    <th>Dernière connexion</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listCustomers.map((customer,i) => {
                                        return(
                                            <tr key={i}>
                                                <td>{customer.lastname} {customer.firstname}</td>
                                                <td>{customer.email}</td>
                                                <td>{customer.userGroup.code === 'superadmin' ? "SUPER ADMIN" : customer.userGroup.code}</td>
                                                <td>{customer.lastLogin ? moment(customer.lastLogin).format('DD/MM/YYYY') : 'N/A'}</td>
                                                <td>
                                                    <Button label="Éditer l'utilisateur" icon={<Edit size="1rem"/>} onClick={() => handleClickEdit(customer)}/>
                                                    {
                                                        customer.userGroup.code !== "superadmin" && customer.id !== getUser?.id ?
                                                            <Button className={classes.buttonRed} icon={<Trash2 size="1rem"/>} onClick={() => handleClickDelete(customer.id)} style={{marginLeft: 8}}/>
                                                        : null
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>   
                        <div className={modaleOpen ? classes.modal : classes.modalClose}>
                            <div className={classes.backgroundModal} onClick={() => handleClose()}/>
                            <div className={classes.modalContainer}>
                                {renderModaleContent()}
                            </div>
                        </div>
                    </div> 
                : <p>Pas d'utilisateurs</p>
            }
        </div>
    )
}