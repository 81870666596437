import classes from "./stockItem.module.css";
import {Info} from "react-feather"
import moment from 'moment'

export default function StockItem(props){
    const {stock, state} = props;
    const { children } = stock;
    const date = moment(stock.updatedAt.date).format('DD/MM/YYYY, h:mm:ss');
    return (
        <tr className={stock.stockTotal == 0 ? classes.rootRed : classes.rootGreen}>
            {
                state.clientCode.visibility == true ?
                    <td>{stock.clientCode}</td>
                    : null
            }
            <td>{stock.articleCode}</td> 
                        <td onClick={stock.reservation > 0 ? () => props.handleClick(stock, 'reservation') : null}><div className={classes.containerIcon} style={{cursor: stock.reservation > 0 ? 'pointer' : 'inherit'}}>{stock.reservation}{stock.reservation > 0 ? <Info size="1.5rem"/> : null}</div></td> 
                        <td onClick={(stock.preparation > 0 && stock.hasDetailPrepa) ? () => props.handleClick(stock, 'preparation') : null}><div className={classes.containerIcon} style={{cursor: (stock.preparation > 0 && stock.hasDetailPrepa) ? 'pointer' : (stock.preparation > 0) ? 'not-allowed' : 'inherit'}} title={(stock.preparation > 0 && !stock.hasDetailPrepa) ? 'Pas de détails pour cet article' : null}>{stock.preparation}{stock.preparation > 0 ? <Info size="1.5rem" style={{fill: stock.hasDetailPrepa ? null:'gray'}} /> : null}</div></td> 
                        <td>{stock.disponible}</td> 
                        <td onClick={stock.rupture > 0 ? () => props.handleClick(stock, 'rupture') : null}><div className={classes.containerIcon} style={{cursor: stock.rupture > 0 ? 'pointer' : 'inherit'}}>{stock.rupture}{stock.rupture > 0 ? <Info size="1.5rem"/> : null}</div></td> 
                        <td>{stock.stockTotal}</td> 
                        <td></td>
        </tr>
    )
}
