import classes from './item.module.css'

export default function ArticleItem(props){
    const {item} = props;
    
    let isRupture = false
    if (props?.rupture?.[item.codeArticle]) {
        isRupture = true
    }

    return(
        <tr className={classes.root} style={{color: isRupture ? 'red' : null}}>
            {/* <td>{moment(item.created_at.date).format('DD-MM-YYYY')}</td> */}
            <td>{isRupture ? '[RUPTURE]' : null} {item.codeArticle}</td>
            <td>{item.libelleArticle}</td>
            <td>{item.qte}</td>
            <td>{item.lot}</td>
        </tr>
    )
}