import DefaultCard from '../../components/Cards/defaultCard';
import classes from './logistique.module.css';
import ReactECharts from 'echarts-for-react'; 

const Logistique = props=>{
    const {stats}=props    
    
    if (stats?.colis?.total) delete stats.colis.total
    let colisData = Object.values(stats.colis).map((v) => v.total);

    if (stats?.commandes?.total) delete stats.commandes.total
    let commandesData = Object.values(stats.commandes).map((v) => v.total);

    var topInvoiceData = {
      data: [],
      series: []
    };

    stats.topInvoice.map((item,i)=>{
      topInvoiceData.data.push(item.value)
      topInvoiceData.series.push(item.total)
    })

    var topStockData = {
      data: [],
      series: []
    };

    stats.topStock.map((item,i)=>{
      topStockData.data.push(item.article_code)
      topStockData.series.push(item.stock_total)
    })


    const option = {        
        tooltip: {
          trigger: 'axis'
        },                        
        xAxis: {          
          data: Object.keys(stats.colis),          
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Colis \npréparés',
            type: 'line',            
            showSymbol: false,
            endLabel: {
                show: true,      
                formatter: '{a}',
                borderColor: "inherit",
                borderWidth: 1,
                padding:[5 ,10],
                borderRadius:5,
                backgroundColor:"white",
                verticalAlign:"bottom",
                lineHeight:15,                
            },
            data: colisData
          },
          {
            name:`Commandes \nexpédiées`,
            type: 'line',          
            showSymbol: false,
            endLabel: {
                show: true,      
                formatter: '{a}',            
                borderColor: "inherit",
                borderWidth: 1,
                padding:[5 ,10],
                borderRadius:5,
                backgroundColor:"white",  
                verticalAlign:"bottom",
                lineHeight:15,                
            },
            label: {
                show: false,                  
            },
            data: commandesData
          },          
        ]
    };       
    
    const topInvoiceOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        textStyle: {
          color: '#FEFEFE'
        }
      },
      textStyle: {
        color: "#fff"
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: topInvoiceData.data.reverse()
      },
      series: [
        {
          itemStyle: {normal: {color: '#F1F1F1'}},
          type: 'bar',
          data: topInvoiceData.series.reverse()
        }
      ]
    };
      
    const topStockOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: topStockData.data.reverse()
      },
      series: [
        {
          type: 'bar',
          data: topStockData.series.reverse()
        }
      ]
    };

    return (
        <div className={classes.root} >
            <DefaultCard >
                <h3>colis préparés et commandes expédiées</h3>  
                <div className={classes.chartContainer} >
                  <ReactECharts option={option} />               
                </div>   
            </DefaultCard>
            <div className={classes.container} >
                <DefaultCard backgroundColor={'#004A95'} color={"white"} >
                    <h3>top 10 produits les plus sortis</h3>   
                    <div className={classes.chartContainer}>
                          
                      <ReactECharts option={topInvoiceOption} />
                    </div>
                </DefaultCard>
                <DefaultCard border={"2px inset #004A95"}>
                    <h3>top 10 produits les plus en stock</h3>                    
                    <div className={classes.chartContainer}>
                      <ReactECharts option={topStockOption} />             
                    </div>
                </DefaultCard>
            </div>
        </div>
    )
}

export default Logistique