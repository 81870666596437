import { useEffect, useState } from 'react';
import {fetchApi} from '../../utils/fetchApi';
import classes from './dashboard.module.css';
import Button from "../../components/Button/button";
import moment from 'moment';
import frLocale from "moment/locale/fr";
import Loader from '../../components/Loader';
import ButtonSwitch from '../../components/Button/buttonSwitch';
import Transport from './transport';
import Logistique from './logistique';
import infoIco from '../../assets/images/icons-info.svg';
import Popup from './popup';

function Dashboard(props){
    props.logout(true, false);
    moment.locale('fr', [frLocale])
    const [lastUpdate, setLastUpdate] = useState(null);
    const [errorApi, setErrorApi] = useState(null);
    const [loadingLog, setLoadingLog] = useState(true);
    const [stats, setStats] = useState({});
    const [periode, setPeriode] = useState();
    const [startAt, setStartAt] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endAt, setEndAt] = useState(moment().format('YYYY-MM-DD'));
    const [page, setPage] = useState('transport');    

    
    const [popupOpen, setPopupOpen] = useState(false);
    const [importsLoading, setImportsLoading] = useState(true);
    const [imports, setImports] = useState(null);
    const [importsPage, setImportsPage] = useState(1);
    const [importsItemsPerPage, setimportsItemsPerPage] = useState(10);

    const handeGetStats = async (startDate = false, endDate = false, forceRefresh = false) =>{
        console.log('getStats');
        setLoadingLog(true);

        var date = (startDate) ? startDate : startAt
        var dateFin = (endDate) ? endDate : endAt
        setStartAt(date)
        setPeriode('du ' + moment(date).locale('fr').format('D MMMM YYYY') + ' au ' + moment(dateFin).locale('fr').format('D MMMM YYYY'))
        
        let data = await fetchApi('stti/flat-dashboard',{startDate: date, endDate: dateFin, refreshCache: forceRefresh});
        
        if (data.success === false) {
            setErrorApi(data.message)
            setLoadingLog(false)
            return
        }
        setErrorApi(null)
        setStats(data.stats)
        setLastUpdate(data.lastUpdate.date)
        setLoadingLog(false)
    }

    useEffect(() => {
        setImportsLoading(true)

        if (importsPage < 1) {
            setImportsPage(1);
            return;
        }

        const getImport = async () => {
            let data = await fetchApi(`stti-imports?itemsPerPage=${importsItemsPerPage}&page=${importsPage}&order[createdAt]=desc`, null, 'GET');

            setImports(data)
            setImportsLoading(false)
        }

        getImport()

    }, [importsPage])
    
    useEffect(() => handeGetStats(), [])
    

    const handleChangeDate = (event, dateToChange) => {
        if (dateToChange === 'start') {
            setStartAt(event.target.value)
        } else if(dateToChange === 'end') {
            setEndAt(event.target.value)
        }
    }

    let getUser = JSON.parse(localStorage.getItem('USER'));

    return(
        <div className={classes.root}>
            <div className={classes.containerLog}>
                {
                    getUser?.userGroup?.code === "superadmin" ?
                    <span className={classes.infoImport} onClick={() => setPopupOpen(true)}>
                        Infos import
                        <img width={32} src={infoIco} />
                    </span>
                        : null
                }

                <div className={classes.buttonContainer}>
                    <ButtonSwitch active={page === 'transport'} onClick={() => setPage("transport")} label={"Transport"} />
                    <ButtonSwitch active={page === 'logistique'} onClick={() => setPage("logistique")} label={"Logistique"} />
                </div>                        
                        {
                            loadingLog ?
                                <>
                                    <h1>Votre tableau de bord</h1>
                                    <Loader label="Chargement des stats" />
                                </>
                            : 
                                (
                                    errorApi ? 
                                    <>
                                        <h1>Votre dashboard</h1>
                                        <input className={classes.inputTypeDate} type="date" onChange={(e) => handleChangeDate(e)} value={startAt}/>
                                        <input className={classes.inputTypeDate} type="date" onChange={(e) => handleChangeDate(e)} value={endAt}/>
                                        <div style={{maxWidth: '100%'}}>
                                            <h3>{errorApi}</h3>
                                            <Button onClick={() => handeGetStats()} label={"Réessayer"} />
                                        </div>                                    
                                    </>
                                    :
                                    <>
                                        <div className={classes.containerHeader}>
                                            <h1>Votre dashboard</h1>
                                            <div style={{textAlign: 'center'}}>
                                                <p style={{marginBottom: '5px', color: 'grey'}}>Dernière MAJ: {moment(lastUpdate).format('D MMMM YYYY')}</p>
                                                <Button onClick={() =>handeGetStats(startAt, endAt, true)} label={"Recalculer les stats"} />
                                            </div>                                            
                                        </div>
                                        <div style={{display: 'flex', maxHeight: 30}}>
                                            <div className={classes.date} style={{width: 270}}>
                                                <p className={classes.dateTitle}>Date de début</p>
                                                <input className={classes.inputTypeDate} type="date" onChange={(e) => handleChangeDate(e, "start")} value={startAt}/>
                                            </div>
                                            <div className={classes.date} style={{width: 270}}>
                                                <p className={classes.dateTitle}>Date de fin</p>
                                                <input className={classes.inputTypeDate} type="date" onChange={(e) => handleChangeDate(e, "end")} value={endAt}/>
                                                
                                            </div>
                                            <Button onClick={() => handeGetStats(startAt, endAt, true)} label={"Calculer les stats pour cette période"} />
                                        </div>
                                        

                                        
                                        <p>Période en cours: {periode}</p>
                                        {
                                            page === 'transport'&&
                                            <Transport stats={stats.TRANS}/>
                                        }
                                        {
                                            page === 'logistique'&&
                                            <Logistique stats={stats.LOG}/>
                                        }
                                        {/* <Stats stats={stats} periode={periode}/> */}
                                    </>
                                )
                        }
            </div>

            {
                popupOpen ? 
                <Popup
                    handleClose={() => setPopupOpen(false)}
                    imports={imports}
                    setPage={setImportsPage}
                    page={importsPage}
                    isLoading={importsLoading}
                    itemsPerPage={importsItemsPerPage}
                /> : null
            }

        </div>
    )
}

export default Dashboard;