import defaultClasses from './textfield.module.css';
import { useState } from 'react';


export default function Textfield(props){
    const {defaultValue = '', name, onChange, placeholder, type='text', disabled= false, style} = props;

    const classes = {...defaultClasses,...props.classes}

    const [value,setValue] = useState(defaultValue);

    const handleChange = (event) =>{
        setValue(event.target.value);
        if(onChange){
            onChange(name,event.target.value)
        }
    }
    return(
        <>
            <input value={value} onChange={handleChange} name={name} className={classes.root} placeholder={placeholder} type={type} disabled={disabled} style={{...style}} />
            {props.children}
        </>
    )
}
