import DefaultCard from "../../../components/Cards/defaultCard";
import classes from './popup.module.css'

const PopupObservation = ({handleClose, observation}) => {
        return (
            <>
            <div className={classes.backgroundContainer} onClick={handleClose}></div>
            <div className={classes.popup}>
                <div className={classes.heading_open}>
                    <h2 className={classes.title}>Commentaires</h2>
                </div>
                <div className={classes.card}>
                    <DefaultCard>
                        
                        <h2>Chargement</h2>
                        <p>{(observation?.chargement) ? observation?.chargement : 'Pas encore de commentaire'}</p>


                        <h2>Livraison</h2>
                        <p>{(observation?.livraison) ? observation?.livraison : 'Pas encore de commentaire'}</p>
                        
                        <div className={classes.containerButton}>
                            <button className={classes.button} onClick={handleClose}>Fermer</button>
                        </div>
                    </DefaultCard>
                </div>
            </div>
            </>
        )
    }

export default PopupObservation;