import DefaultCard from "../../components/Cards/defaultCard";
import Loader from "../../components/Loader/loader";
import classes from './popup.module.css'

const Popup = ({handleClose, imports, isLoading, page, setPage, itemsPerPage}) => {
        return (
            <>
            <div className={classes.backgroundContainer} onClick={handleClose}></div>
            <div className={classes.popup}>
                <div className={classes.heading_open}>
                    <h2 className={classes.title}>Historique des imports</h2>
                </div>
                <div className={classes.card}>
                    <DefaultCard>
                        {
                            isLoading ? <Loader />
                            :
                            <>
                                <table className={classes.importTable}>
                                    <thead>
                                        <tr>
                                            <th>Fichier</th>
                                            <th>Début import</th>
                                            <th>Fin import</th>
                                            <th>Items traités</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                            
                                {
                                imports.map(elem => {
                                    return(
                                        <tr>
                                            <td>{elem.filename}</td>
                                            <td>{(new Date(elem.createdAt)).toLocaleString()}</td>
                                            <td>{(new Date(elem.finishedAt)).toLocaleString()}</td>
                                            <td>{elem.totalImported}</td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                                </table>
                                
                                <div className={classes.pageInfo}>
                                    <span>Page: {page}</span>
                                    <div className={classes.containerButton}>
                                        <button className={classes.buttonYellow} onClick={() => setPage(page - 1)}
                                            disabled={(page === 1)}
                                        >Précédent</button>
                                        <button className={classes.buttonYellow} onClick={() => setPage(page + 1)}
                                            disabled={(imports.length !== itemsPerPage)}
                                        >Suivant</button>
                                    </div>
                                </div>
                            </>
                        }

                        <div className={classes.containerButton}>
                            <button className={classes.button} onClick={handleClose}>Fermer</button>
                        </div>
                    </DefaultCard>
                </div>
            </div>
            </>
        )
    }

export default Popup;