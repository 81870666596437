import axios from "axios";

export const fetchApi = (url,data,method="POST", auth = true, type = 'application/json') =>{
    const token = localStorage.getItem('AUTH_TOKEN');
    var headers = {};

    if (auth && token)
        headers = {
            Authorization: `Bearer ${token}`
        }

    // if (type === 'application/json')
    //     headers.append('Content-Type', type);

    return new Promise(async(resolve, reject)=>{
        var requestOptions = {
            headers,
            mode: 'cors',
            url : process.env.REACT_APP_API_URL+"/"+url,
            method: method,
            data
        };
        axios(
            requestOptions
        ).then(function(res){
            resolve(res.data);
        }).catch((error) => reject(error))
    })

}

export const fetchShell = (type,data,method="POST") => {
    return new Promise(async(resolve)=>{
        let result = await fetchApi(`public/shells/${type}/research`,data,method )
        resolve(result);
    })
}

export const fetchDetails = (id, method = "GET") => {
    return new Promise(async (resolve) => {
        let result = await fetchApi(`stti/flat-orders/${id}`, null, method)
        resolve(result);
    })
}

export const fetchTracking = (id,data,method="GET") => {
    return new Promise(async(resolve)=>{
        let result = await fetchApi(`public/flat-report/${id}`,null,'GET')
        resolve(result);
    })
}

export const fetchResearch = (data,method="POST") => {
    return new Promise(async(resolve)=>{
        let result = await fetchApi(`stti/flat-orders`,data,method)
        resolve(result);
    })
}

export const getActivityList = (data,method="GET") => {
    return new Promise(async(resolve)=>{
        let result = await fetchApi(`stti/orders-libelle`,data,method)
        resolve(result);
    })
}

export const fetchStock = (data,method="GET", params) => {
    return new Promise(async(resolve)=>{
        let constructUrl = `stti/stock`;
        let i = 0
        for (const [key, value] of Object.entries(params)) {
            if (value && value !== 'false'){
                if (i === 0){
                    constructUrl += `?${key}=${value}`
                }else{
                    constructUrl += `&${key}=${value}`
                }
            }
            i++
        }
        let result = await fetchApi(constructUrl,data,method)
        resolve(result);
    })
}
