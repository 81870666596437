import ArticleItem from './item';
import classes from './orderArticles.module.css'

export default function orderArticles(props){
    const {datas} = props;

    return(
        datas && datas.length > 0 ? 
            <div className={classes.root}>
                <div className={classes.container}>
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                {/* <th>Date</th>   */}
                                <th>Réference</th>                          
                                <th>Libellé</th>                          
                                <th>Quantité</th>                          
                                <th>Lot</th>                          
                            </tr>

                        </thead>
                        <tbody>
                            {datas.map((e,index)=>(
                                <ArticleItem item={e} key={`orderArticle-${index}`} rupture={props.rupture} />
                            ))}
                        </tbody>
                    </table>                    
                </div>

            </div>
        : 
        <div className={classes.root}>
            <div className={classes.container}>
                <p style={{textAlign: 'center'}}>Pas d'articles renseignés</p>
            </div>
        </div>
    )
}