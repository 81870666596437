import { useEffect, useState } from 'react';
import {fetchApi} from '../../utils/fetchApi';
import { useNavigate } from 'react-router-dom';
import classes from './login.module.css';
import DefaultCard from "../../components/Cards/defaultCard";
import Textfield from "../../components/Fields/Textfield/textfield";
import Button from "../../components/Button/button";
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER } from '../../queries/customers';
import Loader from '../../components/Loader';

function Login(props){
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [token, setToken] = useState(null);
    const [loadingLog, setLoadingLog] = useState(false);
    const [loginError, setLoginError] = useState(false);

    let nav = useNavigate();

    const { loading, error, data } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'network-only',
        skip: !token
    });

    const handleLogin = async () =>{
        localStorage.clear();
        setLoadingLog(true);
        try {
            var data = await fetchApi('authentication-token',{email, password});    
        } catch (error) {
            localStorage.clear();
            setLoginError(true)
            setLoadingLog(false)
        }
        
        if(data.token){
            setToken(data.token);
            localStorage.setItem('AUTH_TOKEN', data.token);
            localStorage.setItem('AUTH_TOKEN_EXPIRY', moment().add(4, 'hours').toISOString());
            localStorage.setItem('AUTH_EMAIL', email);
        } else {
            localStorage.clear();
            setLoginError(true)
            setLoadingLog(false)
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    }

    useEffect(()=>{
        let getToken = localStorage.getItem('AUTH_TOKEN');
        setToken(getToken)
        props.logout(false, true);
    },[]);

    useEffect(()=>{
        document.addEventListener("keydown", handleKeyPress, false);

        return () => document.removeEventListener("keydown", handleKeyPress, false);
    },[email, password]);

    useEffect(async()=>{
        if (data?.meSttiUser){
            localStorage.setItem('USER', JSON.stringify(data.meSttiUser));
            setLoadingLog(false);
            props.log();
            nav('/orders');
        }
    },[data]);

    useEffect(async()=>{
        setLoadingLog(false);
    },[error]);

    return(
        <div className={classes.root}>
            <div className={classes.containerLog}>
                <DefaultCard>
                    <div className={classes.insideCard}>
                        <div style={{maxWidth: '100%'}}>
                            <h1>Connectez-vous à votre espace</h1>
                        </div>
                        {
                            loadingLog ?
                                <Loader label="Connexion en cours" />
                            : 
                                <>
                                    <div style={{maxWidth: '100%'}}>
                                        <form style={{maxWidth: 1280}}>
                                            <div style={{marginBottom: 10}}>
                                                {
                                                    loginError ? <h3 style={{color:"red", textAlign: "center", padding: 10}}>
                                                        Une erreur est survenue, merci de réessayer.
                                                    </h3> : null
                                                }
                                                <div className={classes.containerInput}>
                                                    <div className={classes.label}>
                                                        <label>Adresse e-mail*</label>
                                                    </div>
                                                    <div style={{position: 'relative', width: '100%'}}>
                                                        <Textfield id="email" name="email" placeholder="Adresse e-mail" onChange={(e, value) => setEmail(value)} type="text" value={email} />
                                                    </div>
                                                </div>
                                                <div className={classes.containerInput}>
                                                    <div className={classes.label}>
                                                        <label>Mot de passe*</label>
                                                    </div>
                                                    <div style={{position: 'relative', width: '100%'}}>
                                                        <Textfield id="password" name="password" placeholder="Mot de passe" onChange={(e, value) => setPassword(value)} type="password" value={password} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <Button onClick={() => handleLogin()} label={"Se connecter"} />
                                </>
                        }
                    </div>
                </DefaultCard>
            </div>
        </div>
    )
}

export default Login;